const images = {
    ornamental: [
        '../assets/ornamental/clotures-ornamentales-1.jpg',
        '../assets/ornamental/clotures-ornamentales-2.jpg',
        '../assets/ornamental/clotures-ornamentales-3.jpg',
        '../assets/ornamental/clotures-ornamentales-4.jpg',
        '../assets/ornamental/clotures-ornamentales-5.jpg',
        '../assets/ornamental/clotures-ornamentales-6.jpg',
        '../assets/ornamental/clotures-ornamentales-7.jpg',
        '../assets/ornamental/clotures-ornamentales-8.jpg',
        '../assets/ornamental/clotures-ornamentales-9.jpg',
        '../assets/ornamental/clotures-ornamentales-10.jpg',
    ],
    serie5000: [
        '../assets/serie5000/clotures-serie5000-1.jpg',
        '../assets/serie5000/clotures-serie5000-2.jpg',
    ],
    frost: [
        '../assets/frost/frost_01.jpg',
        '../assets/frost/frost_02.jpg',
        '../assets/frost/frost_03.jpg',
        '../assets/frost/frost_04.jpg',
        '../assets/frost/frost_05.jpg',
        '../assets/frost/frost_06.jpg',
        '../assets/frost/frost_07.jpg',
        '../assets/frost/frost_08.jpg',
        '../assets/frost/frost_09.jpg',
        '../assets/frost/frost_10.jpg',
        '../assets/frost/frost_11.jpg',
        '../assets/frost/frost_12.jpg',
        '../assets/frost/frost_13.jpg',
        '../assets/frost/frost_14.jpg',
        '../assets/frost/frost_15.jpg',
        '../assets/frost/frost_16.jpg',
        '../assets/frost/frost_17.jpg',
        '../assets/frost/frost_18.jpg',
        '../assets/frost/frost_19.jpg',
        '../assets/frost/frost_20.jpg',
        '../assets/frost/frost_21.jpg',
        '../assets/frost/frost_22.jpg',
        '../assets/frost/frost_23.jpg',
        '../assets/frost/frost_24.jpg'
    ],
    composite: [
        '../assets/composite/composite-1.jpg',
        '../assets/composite/composite-2.jpg',
        '../assets/composite/composite-3.jpg',
        '../assets/composite/composite-4.jpg',
        '../assets/composite/composite-5.jpg',
        '../assets/composite/composite-6.jpg',
        '../assets/composite/composite-7.jpg',
        '../assets/composite/composite-8.jpg',
        '../assets/composite/composite-9.jpg',
        '../assets/composite/composite-10.jpg',
        '../assets/composite/composite-11.jpg',
        '../assets/composite/composite-12.jpg',
        '../assets/composite/composite-13.jpg',
        '../assets/composite/composite-14.jpg',
        '../assets/composite/pvc-1.jpg',
        '../assets/composite/pvc-2.jpg',
        '../assets/composite/pvc-3.jpg',
        '../assets/composite/pvc-4.jpg',
        '../assets/composite/Zen-1.jpg',
        '../assets/composite/Zen-5.jpg',
        '../assets/composite/Zen-7.jpg',
        '../assets/composite/zentrellis-1.jpg',
        '../assets/composite/zentrellis-2.jpg',
        '../assets/composite/zentrellis-3.jpg',
    ],
    ramp:[
        '../assets/ramp/ramp-1.jpg',
        '../assets/ramp/ramp-2.jpg',
        '../assets/ramp/ramp-3.jpg',
        '../assets/ramp/ramp-4.jpg',
        '../assets/ramp/ramp-5.jpg',
        '../assets/ramp/ramp-6.jpg',
        '../assets/ramp/ramp-7.jpg',
    ],
    commercial: [
        '../assets/commercial/commercial_01.jpg',
        '../assets/commercial/commercial_02.jpg',
        '../assets/commercial/commercial_03.jpg',
        '../assets/commercial/commercial_04.jpg',
        '../assets/commercial/commercial_05.jpg'
    ],
    glass: [
        '../assets/glass/cloture-en-verre-1.jpg',
        '../assets/glass/cloture-en-verre-2.jpg',
        '../assets/glass/cloture-en-verre-3.jpg',
        '../assets/glass/cloture-en-verre-4.jpg',
    ],
    aluminium: [
        '../assets/aluminium/aluminium_01.jpg',
    ],
    pool: [
        '../assets/pool/pool-1.jpg',
        '../assets/pool/pool-2.jpg',
        '../assets/pool/pool-3.jpg',
        '../assets/pool/pool-4.jpg',
        '../assets/pool/pool-5.jpg',
        '../assets/pool/pool-6.jpg',
        '../assets/pool/pool-7.jpg',
        '../assets/pool/pool-8.jpg',
        '../assets/pool/pool-9.jpg',
        '../assets/pool/pool-10.jpg',
        '../assets/pool/pool-11.jpg',
        '../assets/pool/pool-12.jpg',
        '../assets/pool/pool-13.jpg',
        '../assets/pool/pool-14.jpg',
    ]
};

export default images;